<template>
  <!--  -->
  <div
    ref="container"
    :style="addVisible ? 'overflow:hidden;' : ''"
    class="cardDetailContainer"
  >
    <div class="title">{{ selfData.nickname }}的名片</div>
    <div class="selfCardWrap">
      <img
        :src="selfData.cardUrl"
        :class="imgStatus == 1 ? 'imgheng' : 'imgshu'"
        alt=""
      />
      <div class="selfCardDetail">
        <div class="selfRow">
          <span>名字：</span>
          <span>{{ selfData.name || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>职位：</span>
          <span>{{ selfData.position || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>公司：</span>
          <span>{{ selfData.company || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>地址：</span>
          <span>{{ selfData.address || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>手机：</span>
          <span>{{ selfData.mobile || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>电话：</span>
          <span>{{ selfData.telephone || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>微信：</span>
          <span>{{ selfData.wechart || "无" }}</span>
        </div>
        <div class="selfRow">
          <span>邮箱：</span>
          <span>{{ selfData.email || "无" }}</span>
        </div>
      </div>
    </div>
    <div class="title">{{ selfData.nickname }}收到的名片</div>
    <div class="tableContainer">
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
            @row-click="rowClick"
          >
            <el-table-column
              label="微信昵称"
              width="120"
              prop="nickname"
              align="center"
            />
            <el-table-column label="姓名" min-width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.name || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="公司名称" min-width="214" align="center">
              <template slot-scope="scope">
                {{ scope.row.company || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="职位" min-width="124" align="center">
              <template slot-scope="scope">
                {{ scope.row.position || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="联系电话" min-width="154" align="center">
              <template slot-scope="scope">
                {{ scope.row.mobile || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="状态" min-width="80" align="center">
              <template>
                <span class="editCommon pointer">查看</span>
                <!-- <span :style="scope.row.readFlag===1?'':'cursor:pointer;'" class="editCommon">{{ scope.row.readFlag===1?'已读':'未读' }}</span> -->
              </template>
            </el-table-column>
          </el-table>

          <!-- </div> -->
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="allTotal.length"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
    <other-detail
      v-if="addVisible"
      ref="project"
      :visible.sync="addVisible"
      :detail="otherDetail"
      :is-area="1"
    />
  </div>
</template>
<script>
import { getCardDetail } from "@/apis/user";
// import { parseTime } from '@/utils/index'
import otherDetail from "./components/otherDetail";
export default {
  components: {
    otherDetail,
  },
  data() {
    return {
      tableData: [],
      allTotal: 0,
      selfData: "",
      imgStatus: 1,
      projectDetail: "",
      addVisible: false,
      loading: false,
      otherDetail: {},
    };
  },
  mounted() {
    // this.tableHeight = this.$refs.container.offsetHeight - 240
    // window.addEventListener('keydown', this.key)
    const { cardId } = this.$route.params;
    this.getCardDetail(cardId);
  },
  // activated() {
  //   window.addEventListener('keydown', this.key)
  //   this.getProjectList(this.current)
  // },
  beforeDestroy() {
    window.removeEventListener("keydown", this.key);
  },
  methods: {
    key(e) {
      if (e.keyCode === 9) {
        // //console.log('tabfalse13')
        e.preventDefault();
      }
    },
    async getCardDetail(cardId) {
      var res = await getCardDetail({ userId: cardId });
      this.tableData = res.result.other.slice(0, 10);
      this.allTotal = res.result.other;
      this.selfData = res.result.self[0];

      const img = new Image();
      img.src = res.result.self[0].cardUrl;
      const that = this;
      img.onload = function () {
        if (img.width > img.height) {
          that.imgStatus = 1;
        } else {
          that.imgStatus = 2;
        }
      };
    },
    rowClick(row) {
      this.addVisible = true;
      this.otherDetail = row;
    },
    // 页码切换
    clickPage(e) {
      // this.getCardDetail(e)
      this.tableData = this.allTotal.slice((e - 1) * 10, e * 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/mixin";
.cardDetailContainer {
  height: calc(100vh - 118px);
  position: relative;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
    padding-top: 40px;
  }
  .tableContainer {
    margin-bottom: 20px;
  }
  .table {
    margin-top: 0;
  }

  .selfCardWrap {
    display: flex;
    padding: 20px 0 0 34px;
    img {
      border-radius: 8px;
    }
    .imgheng {
      width: 450px;
      height: 250px;
    }
    .imgshu {
      width: 200px;
      height: 360px;
    }
    .selfCardDetail {
      margin-left: 40px;
      .selfRow {
        span {
          line-height: 30px;
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
}
</style>
