<template>
  <div class="dialogContain">
    <el-dialog
      ref="dialog"
      :visible.sync="mVisible"
      :show-close="false"
      custom-class="dialog cardDetail"
      @before-close="confirmModal"
    >
      <div class="cardWrap">
        <p class="cardTitle">{{ detail.nickname }}的名片</p>
        <div
          :style="imgStatus == 1 ? 'display:block;' : 'display:flex;'"
          class="selfCardWrap"
        >
          <img
            :src="detail.cardUrl"
            :class="imgStatus == 1 ? 'imgheng' : 'imgshu'"
            alt=""
          />
          <div class="selfCardDetail">
            <div class="selfRow">
              <span>名字：</span>
              <span>{{ detail.name || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>职位：</span>
              <span>{{ detail.position || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>公司：</span>
              <span>{{ detail.company || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>地址：</span>
              <span>{{ detail.address || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>手机：</span>
              <span>{{ detail.mobile || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>电话：</span>
              <span>{{ detail.telephone || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>微信：</span>
              <span>{{ detail.wechart || "无" }}</span>
            </div>
            <div class="selfRow">
              <span>邮箱：</span>
              <span>{{ detail.email || "无" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button class="confirm" @click="confirmModal">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgStatus: 0,
    };
  },
  computed: {
    mVisible: {
      get() {
        return this.visible;
      },
      set(s) {
        this.$emit("update:visible", s);
      },
    },
  },
  created() {
    const img = new Image();
    img.src = this.detail.cardUrl;
    const that = this;
    img.onload = function () {
      if (img.width > img.height) {
        that.imgStatus = 1;
      } else {
        that.imgStatus = 2;
      }
    };
  },

  methods: {
    // 保存dialog
    confirmModal() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.cardWrap {
  // height:101%;
  padding: 0 14px;
  .cardTitle {
    // margin-top: 28px;
    font-size: 24px;
    line-height: 1;
    color: #333333;
  }
  .selfCardWrap {
    display: flex;
    padding: 20px 0 0 0;
    img {
      margin: 0 30px 10px 0;
      border-radius: 8px;
    }
    .imgheng {
      width: 425px;
      height: 225px;
    }
    .imgshu {
      width: 200px;
      height: 360px;
    }
    .selfCardDetail {
      .selfRow {
        span {
          line-height: 30px;
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
}
.el-button {
  margin-top: 30px;
  input {
    font-size: 14px;
  }
}
</style>
